export const Permissions = {
  quote_to_contract: 'quote to contract',
  add_measure_sheets: 'add measure sheets',
  take_payment: 'take payment',
  view_jobs_in_progress: 'view jobs in progress',
  measure_cut_sheets: 'measure / cut sheets',
  price_adjustment: 'price adjustment',
  view_settings: 'view settings',
  tickets: 'tickets',
  access_edit_job_costing: 'access to and edit job costing',
  jobs_closed: 'jobs closed',
  make_credit_memo: 'make credit memo',
  revise_contract: 'revise contract',
  add_discount: 'add discount'
};

export const SHEETDATA = {
  sheet_1: {
    name: 'Starwood Shade' // Alta Roller Shades
  }
};

interface stages {
  quote: string;
  contract: string
  final_measurement: string
  production: string
  cutting_department: string
  final_inspection: string
  schedule_installation: string
  out_for_installation: string
  installation_complete: string
  final_payment: string
  google_review: string
  materials_paid: string
  labor_paid: string
  job_costing: string
  final_review: string
  completed: string
}

export const QUOTE_STAGES: stages = {
  quote: 'quote',
  contract: 'contract',
  final_measurement: 'final_measurement',
  production: 'production',
  cutting_department: 'cutting_department',
  final_inspection: 'final_inspection',
  schedule_installation: 'schedule_installation',
  out_for_installation: 'out_for_installation',
  installation_complete: 'installation_complete',
  final_payment: 'final_payment',
  google_review: 'google_review',
  materials_paid: 'materials_paid',
  labor_paid: 'labor_paid',
  job_costing: 'job_costing',
  final_review: 'final_review',
  completed: 'completed',
};
