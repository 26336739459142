<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar cst-scrollbar"
    nzCollapsible
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo" [ngClass]="isCollapsed ? 'collapsed-div' : ''">
      <a routerLink="/dashboard">
        <ng-container *ngIf="!isCollapsed; else tempLogo">
          <img src="/assets/logo/logo.png" />
        </ng-container>
        <ng-template #tempLogo>
          <img src="/assets/logo/logo.png" class="collapsed-logo" />
        </ng-template>
        <!-- <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo" />-->
        <!-- <h1>Starwood</h1>  -->
      </a>
    </div>
    <ul
      nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <ng-container *ngFor="let data of menu">
        <li
          *ngIf="isEmptyObject(data.sub_menu); else elseSubmenu"
          nz-tooltip
          nz-menu-item
          nzTooltipPlacement="right"
          nzMatchRouter
        >
          <i nz-icon nzType="{{ data.main_menu_icon }}"></i>
          <span
            ><a routerLink="{{ data.main_menu_url }}">{{
              data.main_menu_title
            }}</a></span
          >
        </li>
        <ng-template #elseSubmenu>
          <li
            *ngIf="
              isEmptyObject(data.sub_menu.sub_menu_list);
              else elseSubmenuList
            "
            nz-submenu
            nzTitle="{{ data.main_menu_title }}"
            nzIcon="{{ data.main_menu_icon }}"
          >
            <ul>
              <li nz-menu-item nzMatchRouter>
                <a routerLink="{{ data.main_menu_url }}">{{
                  data.sub_menu.sub_menu_title
                }}</a>
              </li>
            </ul>
          </li>
        </ng-template>
        <ng-template #elseSubmenuList>
          <li
            nz-submenu
            nzTitle="{{ data.main_menu_title }}"
            nzIcon="{{ data.main_menu_icon }}"
          >
            <ul *ngFor="let data of data.sub_menu.sub_menu_list.sub_main_menu">
              <span *ngIf="data.sub_sub_menu == ''">
                <li nz-menu-item nzMatchRouter>
                  <a routerLink="{{ data.sub_menu_url }}">{{
                    data.sub_menu_title
                  }}</a>
                </li>
              </span>
              <span *ngIf="data.sub_sub_menu != ''">
                <li nz-submenu nzTitle="{{ data.sub_menu_title }}">
                  <ul *ngFor="let data of data.sub_sub_menu">
                    <li nz-menu-item nzMatchRouter>
                      <a routerLink="{{ data.sub_sub_menu_url }}">{{
                        data.sub_sub_menu_title
                      }}</a>
                    </li>
                  </ul>
                </li>
              </span>
            </ul>
          </li>
        </ng-template>
      </ng-container>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <div class="profile-menu">
          <ul nz-menu nzMode="horizontal" style="float: right">
            <li nz-menu-item *ngIf="selectedCompany">
              <nz-select [(ngModel)]="selectedCompany" style="width: 200px"
                         (ngModelChange)="selectCompany($event)">
                <nz-option *ngFor="let company of companies"
                           [nzValue]="company" [nzLabel]="company.name"></nz-option>
              </nz-select>
            </li>

            <li nz-submenu nzTitle="Profile" nzIcon="user">
              <ul class="profileSubmenu">
                <ul>
                  <a routerLink="/profile/edit"
                    ><li nz-menu-item>Edit Profile</li></a
                  >
                  <li nz-menu-item (click)="logout()">Logout</li>
                </ul>
              </ul>
            </li>
          </ul>
        </div>
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <i
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          ></i>
        </span>
      </div>
    </nz-header>

    <nz-content class="mainWrapper">
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>
